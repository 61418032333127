import React, { Fragment, useEffect, useState } from 'react';
import Common from '../../../Shared/Common';
import './../Resources/css/block_sponsors.css';

import { Row, Col } from 'reactstrap';
import { ContentPrizes, ContentPrizesFinal, ContentWhoAmI1, ContentWhoAmI2, ContentWhoAmI3 } from './_ContentBlocks';
import Block from '../../../Controls/Scafolding/Block';
import { TextHeader, TextTitle } from '../../../Controls/Digital/TextHeaders';
import imgSponsorsLandscape from '../Resources/Images/KFC23-BANNER-PORTRAIT-LIGHT-min.jpg';
import imgSponsorsHorizontal from '../Resources/Images/KFC23-BANNER-LANDSCAPE-LIGHT-min.jpg';
import imgLogoHorizontal from '../Resources/Images/LogoHorizontal-White.jpg';

const Sponsor = (props) => {
    return (
        <Col lg="3" className="sponsor">
            <a href={props.url} target="_blank">
                <img className="sponsor-item"
                    src={props.imagePath} />
                </a>
        </Col>
    );
}

const Sponsors = (props) => {
    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (

        <Block name="sponsors" isAlt={true}>

            <TextHeader text={
                <Fragment>
                    sponsors & prizes
                </Fragment>
            } />

            <Row>
                <Col lg={{ site: 10, offset: 1 }}>
                    <img src={imgLogoHorizontal} className="img-fluid" />
                </Col>
            </Row>


            <Row className="text-title">
                <Col lg={{ site: 10, offset: 1 }} className="definition">
                    meet <b>all sponsors</b> making the Adreno Sydney Kingfish Cup such a great event
                </Col>
            </Row>

            <Row className="row-bkg">

                <Sponsor url="https://spearfishing.com.au"  imagePath="/Brands/KFC/Sponsors/Adreno-Liveit-Breatheit-Diveit.png" />
                <Sponsor url="https://spearfishing.com.au"  imagePath="/Brands/KFC/Sponsors/ADRENO_OO_Logo.png" />
                <Sponsor url="https://spearfishing.com.au"  imagePath="/Brands/KFC/Sponsors/AUP.png" />
                <Sponsor url="https://spearfishing.com.au"  imagePath="/Brands/KFC/Sponsors/BEUCHAT-2018 black.png" />
                <Sponsor url="https://spearfishing.com.au"  imagePath="/Brands/KFC/Sponsors/Copy of Riffe_wave_logo.png" />
                <Sponsor url="https://spearfishing.com.au"  imagePath="/Brands/KFC/Sponsors/DiveR graphic 1.png" />
                <Sponsor url="https://spearfishing.com.au"  imagePath="/Brands/KFC/Sponsors/DiveR Graphic 2.png" />
                <Sponsor url="https://spearfishing.com.au"  imagePath="/Brands/KFC/Sponsors/MannySub Logo.png" />
                <Sponsor url="https://spearfishing.com.au"  imagePath="/Brands/KFC/Sponsors/MCOMB_SG_LOGO_1_.png" />
                <Sponsor url="https://spearfishing.com.au"  imagePath="/Brands/KFC/Sponsors/Ocean Hunter.png" />
                <Sponsor url="https://spearfishing.com.au"  imagePath="/Brands/KFC/Sponsors/Riffe.gif" />
                <Sponsor url="https://spearfishing.com.au"  imagePath="/Brands/KFC/Sponsors/Riffe_wave_logo.png" />
                <Sponsor url="https://spearfishing.com.au"  imagePath="/Brands/KFC/Sponsors/ROBALLEN_LOGO.png" />
                <Sponsor url="https://spearfishing.com.au"  imagePath="/Brands/KFC/Sponsors/Salvimar.png" />
                <Sponsor url="https://spearfishing.com.au"  imagePath="/Brands/KFC/Sponsors/Spearo_Suite_Black-01.png" />
                <Sponsor url="https://spearfishing.com.au"  imagePath="/Brands/KFC/Sponsors/Suunto.png" />

            </Row>

            <Row className="text-title">
                <Col lg={{ site: 10, offset: 1 }} className="definition">
                </Col>
            </Row>

            {/*<Row className="text-title">*/}
            {/*    <Col lg={{ size: 10, offset: 1 }} className="definition text-center">*/}
            {/*        <h2>With these great <b>prizes</b> up for grabs!!!!</h2>*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            {/*<Row>*/}
            {/*    <Col lg={{ site: 10, offset: 1 }}>*/}
            {/*        <img src={imgSponsorsLandscape} className="img-fluid" />*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            {/*<Row className="row-bkg">*/}
            {/*    <Sponsor*/}
            {/*        url="https://spearfishing.com.au"*/}
            {/*        imagePath="/Brands/KFC/Sponsors/logo-adreno.webp" />*/}
            {/*    <Sponsor*/}
            {/*        url="https://www.rollerspearguns.com/"*/}
            {/*        imagePath="/Brands/KFC/Sponsors/logo-mannysub.png" />*/}
            {/*    <Sponsor*/}
            {/*        url="https://roballendiving.com/"*/}
            {/*        imagePath="/Brands/KFC/Sponsors/logo-roballen.png" />*/}
            {/*    <Sponsor*/}
            {/*        url="https://www.penetratorfins.com/"*/}
            {/*        imagePath="/Brands/KFC/Sponsors/logo-penetrator.png" />*/}

            {/*    <Sponsor*/}
            {/*        url="https://spearfishing.com.au/collections/spearo"*/}
            {/*        imagePath="/Brands/KFC/Sponsors/logo-spearo.png" />*/}
            {/*    <Sponsor*/}
            {/*        url="https://ocean-guardian.com/"*/}
            {/*        imagePath="/Brands/KFC/Sponsors/logo-oceanguardian.png" />*/}
                
            {/*    <Sponsor*/}
            {/*        url="https://www.suunto.com/"*/}
            {/*        imagePath="/Brands/KFC/Sponsors/logo-suunto.png" />*/}

            {/*    <Sponsor*/}
            {/*        url="https://spearfishing.com.au/collections/usa-latex"*/}
            {/*        imagePath="/Brands/KFC/Sponsors/logo-USALatex.png" />*/}

            {/*    <Sponsor*/}
            {/*        url="https://www.sharkbanz.com.au/"*/}
            {/*        imagePath="/Brands/KFC/Sponsors/logo-SharkBanz.png" />*/}

            {/*    <Sponsor*/}
            {/*        url="https://www.spearfishingaustralia.com/"*/}
            {/*        imagePath="/Brands/KFC/Sponsors/logo-SpearfishingAustralia.png" />*/}
            {/*</Row>*/}


            {/*<Row className="text-title">*/}
            {/*    <Col lg={{ size: 10, offset: 1 }} className="definition text-center">*/}
            {/*        <h2>With these great <b>prizes</b> up for grabs!!!!</h2>*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            {/*<ContentPrizesFinal />*/}

        </Block>
    );
}
export default Sponsors;