import React, { Fragment, useEffect, useState } from 'react';
import Common from '../../../Shared/Common';
import Block from '../../../Controls/Scafolding/Block';

import { Row, Col } from 'reactstrap';
import { TextHeader } from '../../../Controls/Digital/TextHeaders';

const About = (props) =>{

    const [blockName, setBlockName] = useState("");

    useEffect(() => {
        setBlockName(props.name);
        Common.traceInit("Block " + blockName);
    });

    return (
        <Block name="about" isAlt={false}>

            <TextHeader text={
                <Fragment>
                    about
                </Fragment>
            } />

            <ContentAboutWrapper
                header="What is the Kingfish cup?"
                content={
                    <Fragment>
                        The Kingfish Cup is about spearing a kingfish, over one weekend in Sydney’s prime Kingfish season, traditionally this is late October through November.
                    </Fragment>
                } />


            <ContentAboutWrapper
                header="Where is it held?"
                content={
                    <Fragment>
                        “Greater Sydney” is the area entrants may enter. It stretches from Bird Island in the Central Coast to Bass Point in the South covering just over 250 kilometres of coastline. Weigh in Stations are at Adreno Sydney itself, Little Manly in Sydney Harbour, and Terrigal, on the Central Coast.
                    </Fragment>
                } />


            <ContentAboutWrapper
                header="Who enters the KFC?"
                content={
                    <Fragment>
                        Such a wide-ranging demographic of both age, sex, and area.  Going on recent years we are expecting over 200 spearfishers entering including sub juniors from 14 years of age right up to Grand Masters (over 65’s), Ladies entries have been strong (9 ladies competed last year). Divers come from as far as Queensland to fish the event. Those who are not competitively focused also fish the Kingfish Cup. We also get relative newcomers fish the Kingfish Cup some of which spearing their first kingfish in the event.
                    </Fragment>
                } />

            <ContentAboutWrapper
                header="How does it work?"
                content={
                    <Fragment>
                        The USFA runs the event, we work with the Adreno Sydney team, and it has been a successful partnership.  Prior to the event, some 8 days before, we host a “get together” at Adreno – putting on some great food - one of our members creates amazing paella, we explain the simple rules, we have guest speakers (spearfishing gurus) that discuss kingfish habits and hunting tips, and this year we have some great scientific data being divulged on Kingfish by a team that is tracking fish via satellite.  A great social night that includes several prizes being given out, for the attendees, and for the early bird registers, including one major prize (to be announced).
                        Since the running of the inaugural event in 2019 we have seen healthy registration numbers from 170 to well over 200 entrants, thus it requires a lot of organising from an efficient team.  Entrants register online, they are given details of the event and how to “sign on” and “sign off” (any times between 0800 and 1600 you may fish multiple times on a day) on each day – (some entrants fish only one day) this lets organisers know who is in the water and importantly when they are back out – safety is paramount.  On safety, the organisers also make the decision on postponing the days fishing if the weather is decided to be inclement.
                        The fish are weighed in at the weigh stations, we try to keep the weights discreet to build some atmosphere for the presentation night the Thursday following the event. 
                    </Fragment>
                } />

            <ContentAboutWrapper
                header="The event is ethical! Sustainable too! "
                content={
                    <Fragment>
                        Kingfish are in healthy numbers along Australia’s east coast. Take 2023, when we held the Cup over two days, we had 235 divers in the water for approximately 1343 hours, of the fish that were caught it worked out to over 83.9 hours of effort per fish, with an average weight of 9.5 kilograms per fish.  Spearfishing is amazing.
                    </Fragment>
                } />
        </Block>
    );
}
export default About;

const ContentAboutWrapper = (props) => {
    return (
        <Row>
            <Col lg={{ site: 10, offset: 1 }} className="definition">
                <p>
                    <b>{props.header}</b>
                </p>
                <p>
                    {props.content}
                </p>
            </Col>
        </Row>
    );
}